import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { useHistory } from "react-router-dom";
import { COLORS } from "../../config";
const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const history = useHistory();
  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      history.push("/");
      setTimeout(() => {
        const element = document.getElementById(id) as HTMLDivElement;
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
          });
        }
        setVisibility(false);
      }, 100);
    };
    const menuItems = [
      {
        title: "About",
        to: "about",
      },
      {
        title: "Why Should I?",
        to: "why",
      },
      {
        title: "Features",
        to: "features",
      },
    ];
    return (
      <>
        {menuItems.map((item) => (
          <CustomNavLinkSmall onClick={() => scrollTo(item.to)}>
            <Span>{t(item.title)}</Span>
          </CustomNavLinkSmall>
        ))}
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button color={COLORS.SECONDARY}>{t("Contact Us")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer
            to="/"
            aria-label="homepage"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="/img/logo.png" style={{ width: 75, height: 64 }} />
            {/* <SvgIcon src="car_front.svg" width="75px" height="64px" /> */}
            {/* <h6 style={{ marginBottom: 0, fontSize: 20 }}>1T4</h6> */}
          </LogoContainer>

          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
