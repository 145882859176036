import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import "./test.css";
import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Language,
  SPara,
  SSS,
} from "./styles";
import { useHistory } from "react-router-dom";
import { COLORS } from "../../config";
import { AiOutlineFacebook } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { CiLinkedin } from "react-icons/ci";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const history = useHistory();

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <NavLink to="/">
                <LogoContainer
                  aria-label="homepage"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src="./img/logo.png" height={64} width={75} />
                </LogoContainer>
              </NavLink>
              <p
                style={{ fontSize: 14, marginBottom: 20, color: COLORS.THIRD }}
              >
                Travel to house, office with your friends, co-workers, neighbour
                to make your daily commute simple, fun and more rewarding
              </p>
              <div
                style={{
                  display: "flex",
                  gap: 20,
                }}
              >
                <SPara>
                  <AiOutlineFacebook size={30} />
                </SPara>
                <SPara>
                  <CiLinkedin size={30} />
                </SPara>
                <SPara>
                  <FaInstagram size={30} />
                </SPara>

                {/* <SocialLink href="https://instagram.com" src="instagram.svg" /> */}
                {/* <SocialLink
                  href="https://www.linkedin.com"
                  src="linkedin.svg"
                /> */}
              </div>
            </Col>
            <Col lg={6} md={6} sm={10} xs={10}>
              <Title>{t("Useful Links")}</Title>
              <Large to="/terms" onClick={scrollToTop}>
                {t("Terms & Conditions")}
              </Large>
              <Large to="/privacy_policy" onClick={scrollToTop}>
                {t("Privacy policy")}{" "}
              </Large>
              <Large to="/refund_policy" onClick={scrollToTop}>
                {t("Refund")}{" "}
              </Large>
              <Large to="/" onClick={scrollToTop}>
                {t("Transaction")}
              </Large>
              <Large to="/delete_account" onClick={scrollToTop}>
                {t("Delete Account")}{" "}
              </Large>
            </Col>

            <Col
              lg={8}
              md={8}
              sm={20}
              xs={20}
              style={{ display: "flex", flexDirection: "column", gap: 10 }}
            >
              <Language>{t("Address")}</Language>
              <Para>
                <SvgIcon src="call.svg" height="16px" width="16px" />
                &nbsp;&nbsp;
                <SPara href={`tel:8008888605`}>8008888605</SPara>
              </Para>
              <div>
                <Para>
                  <SvgIcon src="email.svg" height="16px" width="16px" />
                  &nbsp;&nbsp;
                  <SPara href={`mailto:eternivault@gmail.com`}>
                    eternivault@gmail.com
                  </SPara>
                </Para>
              </div>

              <div style={{ display: "flex" }}>
                <SvgIcon src="home.svg" height="16px" width="16px" />
                &nbsp;&nbsp;
                <div>
                  <Para>F.No 4-61/a/1</Para>
                  <Para>SREERAMNAGAR COLONY PLOT BEARING NO 61</Para>
                  <Para>Manikonda,Hyderabad</Para>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra style={{ paddingBottom: 0 }}>
        <Container border={true}>
          <Row justify="center" align="middle">
            <p
              style={{
                fontSize: 10,
                paddingTop: 10,
                paddingBottom: 0,
                textAlign: "center",
                color: COLORS.THIRD,
              }}
            >
              © 2024 Eternivault Private Limited. All rights reserved. Designed
              & Developed by
              <a
                href="https://www.aptapace.com"
                style={{ fontWeight: "bold", color: COLORS.THIRD }}
              >
                &nbsp;Aptapace
              </a>
              .
            </p>
            <br />
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
